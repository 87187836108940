import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import axios from 'axios';
import { Radio } from 'antd';
import CustomInput from '../../form/customInput';
import { User } from '../../../models/user';
import moment from 'moment';

Chart.register(...registerables);

interface DataType {
  category?: { color: string };
  agent_id: number;
  agent: User;
  total_giro: number;
}

const AgentApexChart = ({ start_date, end_date }: any) => {
  const [data, setData] = useState<DataType[]| any >([]);
  const [loading, setLoading] = useState(false);
  const [seeAgents, setSeeAgents] = useState<number>(30);
  const [seeChartListType, setSeeChartListType] = useState<any>('monthly');
  const [send_transaction_filter, setSendTransactionFilter] = useState(true);

  useEffect(() => {
    setLoading(true);
    let startDate = start_date;
    let endDate = end_date;

    if (seeChartListType === 'weekly') {
      startDate = moment().startOf('isoWeek').format('YYYY-MM-DD');
      endDate = moment().endOf('isoWeek').format('YYYY-MM-DD');
    } else if (seeChartListType === 'monthly') {
      startDate = moment().startOf('month').format('YYYY-MM-DD');
      endDate = moment().endOf('month').format('YYYY-MM-DD');
    } else if (seeChartListType === 'yearly') {
      startDate = moment().startOf('year').format('YYYY-MM-DD');
      endDate = moment().endOf('year').format('YYYY-MM-DD');
    }

    if (send_transaction_filter) {
      axios
        .get(`giros-ranking-agents?limit=${seeAgents}&filter_between[]=${startDate}&filter_between[]=${endDate}&special_sort[by]=total_giro&special_sort[order]=desc`)
        .then(({ data }) => {
          setData(data.transactions.data.data.slice(0, seeAgents)); 
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });
      setSendTransactionFilter(false);
    }
  }, [start_date, end_date, seeAgents, seeChartListType, send_transaction_filter]);

  const labels = data?.map((agent:any) => `${agent.agent.first_name} ${agent.agent.last_name}`);
  const totalGiros = data?.map((agent:any) => agent.total_giro);
  const colors = data?.map((agent:any) => agent?.agent?.category?.color || '#000000');
  const colorsByLabels = data?.map((agent:any) => agent?.agent?.category?.name || '#000000');

  const barData = {
    labels: labels.slice(0, seeAgents), 
    datasets: [
      {
        label: colorsByLabels,
        data: totalGiros.slice(0, seeAgents),
        backgroundColor: colors.slice(0, seeAgents),
      },
    ],
  };

  const barOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const value = context.raw;
            return `${value.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}`;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
    animation: {
      onComplete: () => {
        const chartInstance = Chart.getChart('agentBarChart') as any;
        const ctx = chartInstance.ctx;
        const meta = chartInstance.getDatasetMeta(0);
        meta.data.forEach((bar: any, index: number) => {
          const agentId = data[index].agent.id;
          const img = new Image();
          img.src = `https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745`;
          img.onload = () => {
            const x = bar.x - 20;
            const y = bar.y - 40;
            ctx.drawImage(img, x, y, 40, 40);
          };
        });
      },
    },
  };

  return (
    <>
        <div className="d-lg-flex d-block justify-content-between mb-4">
          <Radio.Group
            className="d-flex justify-content-center justify-lg-content-end d-sm-block"
            value={seeChartListType}
            onChange={(e) => { setSendTransactionFilter(true); setSeeChartListType(e.target.value); }}
            buttonStyle="solid"
          >
            <Radio.Button value={'weekly'}>Bu Hafta</Radio.Button>
            <Radio.Button value={'monthly'}>Bu Ay</Radio.Button>
            <Radio.Button value={'yearly'}>Bu Yıl</Radio.Button>
          </Radio.Group>
          <div className="d-flex justify-content-center justify-lg-content-end">
            <div className="align-self-center">
              Gösterilen Danışman Sayısı
            </div> 
            <div className="ms-2 col-3">
              <CustomInput
                type={'number'}
                required={true}
                defaultValue={seeAgents}
                onBlur={(e: any) => { setSeeAgents(e.target.value); setSendTransactionFilter(true); }}
              />
            </div>
          </div>
        </div>

        <Bar id="agentBarChart" data={barData} options={barOptions} />
    </>
  );
};

export default AgentApexChart;
