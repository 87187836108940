import React, { useState, useMemo, useEffect } from 'react';
import AntDesignCalendar from '../../constant/antDesign/calendar';
import useWatchSchedules from '../../../api/hooks/useWatchSchedules';
import { Button, Col, Form, Row, Tag } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import isMobile from '../../constant/isMobile';
import CalendarButton from '../../constant/antDesign/calendarButton';
import AntDesignModal from '../../constant/antDesign/modal';
import AddWatchScheduleModal from '../../modals/watchSchedules/addWatchSchedule';
import WatchScheduleDetailModal from '../../modals/watchSchedules/watchScheduleDetail';

const WatchScheduleIndex: React.FC = () => {
  const startDate = useMemo(() => dayjs().subtract(1, 'month').startOf('month'), []);
  const endDate = useMemo(() => dayjs().endOf('month'), []);
  const [limit, setLimit] = useState<number>(1131);

  const [value, setValue] = useState(() => dayjs('2017-01-25'));
  const [selectedValue, setSelectedValue] = useState(() => dayjs('2017-01-25'));
  const [data, setData] = useState<{ id: number; user: { id: number; fullName: string }; selectedValue: string } | null>(null);
  const [show, setShow] = useState(false);
  const [showWSD, setWSDShow] = useState(false);

  const params = useMemo(() => ({
    'filter_between[]': [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')],
    limit,
  }), [startDate, endDate, limit]);

  const { watchSchedules, loading, reloadWatchSchedules } = useWatchSchedules(params);

  // Refactor dateCellRender logic into useEffect
  const [renderedCells, setRenderedCells] = useState<any>(null);

  useEffect(() => {
    const renderCells = (date: Dayjs) => {
      const schedulesForDate = watchSchedules.filter(schedule =>
        dayjs(schedule.schedule_date).isSame(date, 'day')
      );

      return schedulesForDate.map(schedule => {
        const actionCount = Array.isArray(schedule.action) ? Object.keys(schedule.action).length : 0;
        const fullName = isMobile
          ? `${schedule.user?.first_name.charAt(0)}${schedule.user?.last_name.charAt(0)}`
          : `${schedule.user?.first_name} ${schedule.user?.last_name}`;

        return (
          <CalendarButton
            key={schedule.id}
            className="text-center d-block mb-1"
            text={`(${actionCount}) ${fullName}`}
            type={'primary'}
            size={'small'}
            onClick={() => handleScheduleClick(schedule)}
          />
        );
      });
    };

    setRenderedCells(() => renderCells);
  }, [watchSchedules]);

  const handleScheduleClick = (schedule: any) => {
    setShow(false);
    if (schedule.id !== undefined) {
      setData({
        user: {
          id: schedule.id,
          fullName: `${schedule.user?.first_name} ${schedule.user?.last_name}`
        },
        id: schedule.id as number,
        selectedValue: selectedValue.format('DD.MM.YYYY')
      });
      setWSDShow(true);
    } else {
      console.error('Schedule ID is undefined');
    }
  };

  const onSelect = (newValue: Dayjs, selectInfo: any) => {
    if (selectInfo.source === 'date') {
      setValue(newValue);
      setSelectedValue(newValue);
      setShow(true);
    }
  };

  useEffect(() => {
    console.log('Fetching with params:', params);
  }, [params]);

  return (
    <>
      <AddWatchScheduleModal
        selectedValue={selectedValue?.format('DD.MM.YYYY')}
        show={show}
        setShow={setShow}
        onSuccess={reloadWatchSchedules}
      />
      {data && (
        <WatchScheduleDetailModal
          data={data}
          showWSD={showWSD}
          setWSDShow={setWSDShow}
          show={show}
          setShow={setShow}
          onSuccess={reloadWatchSchedules}
        />
      )}

      <AntDesignCalendar dateCellRender={renderedCells} onSelect={onSelect} />
    </>
  );
};

export default WatchScheduleIndex;
