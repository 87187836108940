import { Doughnut } from 'react-chartjs-2';
import CountUp from 'react-countup';
import { NavLink } from 'react-router-dom';
import {Chart,ArcElement} from 'chart.js';
import ReactApexChart from 'react-apexcharts';
Chart.register(ArcElement)

const ApexDoughnutChartWithCard = ({ count, dateTypeName,data,text,url,price=0 }: any) => {
    return (
        <div className="card card-h-90">
        <div className="card-body d-flex align-items-center justify-content-between">
            <div style={{ flexBasis: '50%', maxWidth: '50%' }}>
                <span className="text-muted mb-3 lh-1 d-block text-truncate">
                    <NavLink to={url} className="text-muted">{text}</NavLink>
                </span>
                <h4 className="mb-3">
                    <CountUp end={count} />
                </h4>
                <div className="text-nowrap">
                    {price > 0 ? (
                        <span className="badge bg-soft-success text-success">₺{price}</span>
                    ) : null}
                    <span className="ms-1 text-muted font-size-13">{dateTypeName}</span>
                </div>
            </div>
            <div style={{ flexBasis: '50%', maxWidth: '50%' }}>
                <ReactApexChart
                    options={data.options}
                    series={data.series}
                    type="donut"
                    height="150"
                    width="150"
                />
            </div>
        </div>
    </div>
    );
};

export default ApexDoughnutChartWithCard;
