import React from 'react';
import { Button } from 'antd';

interface CalendarButtonProps {
    type?: 'primary' | 'dashed' | 'link' | 'text' | 'default';
    size?: 'large' | 'middle' | 'small';
    style?: React.CSSProperties;
    className?: string;
    onClick?: () => void;
    text: string;

}

const CalendarButton: React.FC<CalendarButtonProps> = ({
    type = 'primary',
    size = 'small',
    style,
    className,
    onClick,
    text
}) => {
    return (
        <Button
            type={type}
            size={size}
            className={className}
            style={style}
            onClick={onClick}
        >
            {text}
        </Button>
    );
};

export default CalendarButton;
